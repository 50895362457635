<template>
  <div class="basic-card-block">
     <h1  class="sub-module-title">{{title}}
      <span v-if="actions.length > 0">
        <el-button
          v-for=" action in actions"
          style="min-width: 100px"
          type="primary"
          size="small"
          :disabled="action.disabled"
          @click="action.onAction"
          :style="{margin: '2px'}"
        >{{action.label}}</el-button></span>
     </h1>
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    action: Object,
    actions: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    if(this.actions.length == 0 && this.action && this.action.label) {
      this.actions.push( this.action )
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onClick () {
    }
  },
  mounted () {
  }

}

</script>

<style lang="scss" scoped>
  .container {
    padding:0 10px;
  }
</style>
